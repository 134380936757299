@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
a:focus-visible {
	outline: 2px solid #f00;
}

html {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
}

a {
	text-decoration: none;
}

#root {
	min-height: 100vh;
}

#footer {
	border-top: 1px solid #eee;
	padding-top: 2rem;
}

.MuiPickersCalendar-week>div:nth-child(6),
.MuiPickersCalendar-week>div:nth-child(7) {
	background: lightyellow;
}

.MuiPickersCalendar-week>div:nth-child(6)>.MuiPickersDay-day,
.MuiPickersCalendar-week>div:nth-child(7)>.MuiPickersDay-day {
	color: red;
}

input::placeholder {
	font-size: 14px;
}

.ql-align-center {
	text-align: center;
}

.ql-align-justify {
	text-align: justify;
}

.ql-align-right {
	text-align: right;
}

@media print {
	/* All your print styles go here */
	main {
		height: 100% !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	#page-container {
		padding-top: 0 !important;
	}
	header, #footer, #breadcrumb, #header, #publish-card {
		display: none !important;
	}
	.avoidBreak {
		break-inside: avoid;
	}
}
[data-nowrap] {
	white-space: nowrap;
}

::-webkit-scrollbar {
	width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
	background: darkgray;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #2E6228;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #3da239;
}
